import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "pistol": 0,
    "submaсhineGun": 0,
    "shotgun": 0,
    "sniper": 0,
    "rifle": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-2')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News2 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №2 (Уникальные навыки)</h1>
                <p className="newsPageText">
                    Сегодня мы хотим рассказать про некоторые навыки, которые переведут ваш игровой процесс на
                    совершенно другой уровень!
                    <br/>
                    <br/>
                    Станьте профессиональным <span className="word">стрелком</span> с нашей новой системой прокачки,
                    которая обеспечивает
                    персонализированный прогресс и неповторимое ведение боя.
                    <br/>
                    Теперь в зависимости от навыка, будет меняться <span className="word">точность стрельбы</span> при
                    статичном положении
                    персонажа, а также при ходьбе/беге. Кроме того, менее опытные стрелки заметят больший <span
                    className="word">разброс
                    пуль</span> и не смогут в полной мере контролировать <span className="word">отдачу</span>.<br/>
                    А если вы думали, что, научившись виртуозно владеть пистолетом, сможете с лёгкостью стрелять из
                    дробовика, то спешим вас огорчить. Да, <span className="word">для каждого типа оружия свой паттерн стрельбы</span> и
                    умение
                    работает <span className="word">по-разному</span>.
                    <br/>
                    <br/>
                    Чем больше вы практикуетесь, тем лучше становятся ваши навыки. А для тех, кто стремится к
                    совершенству, мы подготовили не просто стрельбище для улучшения ваших навыков, а <span
                    className="word">полноценный
                    бизнес</span> в рамках нашего сервера, где каждый магазин оружия — это отдельный предпринимательский
                    проект с реальной экономикой и возможностью для игроков стать его частью.
                </p>
                <details>
                    <summary>Разница владения умением для пистолетов (Растояние 30м) (Спойлер)</summary>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("pistol")}>&#10094;</div>
                        <img name="pistol" className="slide" src={require('../Asseets/Other/News2/pistol0.png')}
                             alt="pistol"/>
                        <img name="pistol" className="slide" src={require('../Asseets/Other/News2/pistol20.png')}
                             alt="pistol"
                             style={{display: "none"}}/>
                        <img name="pistol" className="slide" src={require('../Asseets/Other/News2/pistol40.png')}
                             alt="pistol"
                             style={{display: "none"}}/>
                        <img name="pistol" className="slide" src={require('../Asseets/Other/News2/pistol60.png')}
                             alt="pistol"
                             style={{display: "none"}}/>
                        <img name="pistol" className="slide" src={require('../Asseets/Other/News2/pistol80.png')}
                             alt="pistol"
                             style={{display: "none"}}/>
                        <img name="pistol" className="slide" src={require('../Asseets/Other/News2/pistol100.png')}
                             alt="pistol"
                             style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("pistol")}>&#10095;</div>
                    </div>
                </details>
                <details>
                    <summary>Разница владения умением для пистолет-пулеметов (Растояние 30м) (Спойлер)</summary>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("submaсhineGun")}>&#10094;</div>
                        <img name="submaсhineGun" className="slide"
                             src={require('../Asseets/Other/News2/submachine0.png')} alt="submaсhineGun"/>
                        <img name="submaсhineGun" className="slide"
                             src={require('../Asseets/Other/News2/submachine20.png')} alt="submaсhineGun"
                             style={{display: "none"}}/>
                        <img name="submaсhineGun" className="slide"
                             src={require('../Asseets/Other/News2/submachine40.png')} alt="submaсhineGun"
                             style={{display: "none"}}/>
                        <img name="submaсhineGun" className="slide"
                             src={require('../Asseets/Other/News2/submachine60.png')} alt="submaсhineGun"
                             style={{display: "none"}}/>
                        <img name="submaсhineGun" className="slide"
                             src={require('../Asseets/Other/News2/submachine80.png')} alt="submaсhineGun"
                             style={{display: "none"}}/>
                        <img name="submaсhineGun" className="slide"
                             src={require('../Asseets/Other/News2/submachine100.png')} alt="submaсhineGun"
                             style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("submaсhineGun")}>&#10095;</div>
                    </div>
                </details>
                <details>
                    <summary>Разница владения умением для дробовиков (Растояние 30м) (Спойлер)</summary>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shotgun")}>&#10094;</div>
                        <img name="shotgun" className="slide" src={require('../Asseets/Other/News2/shotgun0.png')}
                             alt="shotgun"/>
                        <img name="shotgun" className="slide" src={require('../Asseets/Other/News2/shotgun20.png')}
                             alt="shotgun" style={{display: "none"}}/>
                        <img name="shotgun" className="slide" src={require('../Asseets/Other/News2/shotgun40.png')}
                             alt="shotgun" style={{display: "none"}}/>
                        <img name="shotgun" className="slide" src={require('../Asseets/Other/News2/shotgun60.png')}
                             alt="shotgun" style={{display: "none"}}/>
                        <img name="shotgun" className="slide" src={require('../Asseets/Other/News2/shotgun80.png')}
                             alt="shotgun" style={{display: "none"}}/>
                        <img name="shotgun" className="slide" src={require('../Asseets/Other/News2/shotgun100.png')}
                             alt="shotgun" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shotgun")}>&#10095;</div>
                    </div>
                </details>
                <details>
                    <summary>Разница владения умением для винтовок (Растояние 30м) (Спойлер)</summary>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("rifle")}>&#10094;</div>
                        <img name="rifle" className="slide" src={require('../Asseets/Other/News2/rifle0.png')}
                             alt="rifle"/>
                        <img name="rifle" className="slide" src={require('../Asseets/Other/News2/rifle20.png')}
                             alt="rifle" style={{display: "none"}}/>
                        <img name="rifle" className="slide" src={require('../Asseets/Other/News2/rifle40.png')}
                             alt="rifle" style={{display: "none"}}/>
                        <img name="rifle" className="slide" src={require('../Asseets/Other/News2/rifle60.png')}
                             alt="rifle" style={{display: "none"}}/>
                        <img name="rifle" className="slide" src={require('../Asseets/Other/News2/rifle80.png')}
                             alt="rifle" style={{display: "none"}}/>
                        <img name="rifle" className="slide" src={require('../Asseets/Other/News2/rifle100.png')}
                             alt="rifle" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("rifle")}>&#10095;</div>
                    </div>
                </details>
                <details>
                    <summary>Разница владения умением для снайперских винтовок (Растояние 30м) (Без режима прицеливания)
                        (Спойлер)
                    </summary>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("sniper")}>&#10094;</div>
                        <img name="sniper" className="slide" src={require('../Asseets/Other/News2/sniper0.png')}
                             alt="sniper"/>
                        <img name="sniper" className="slide" src={require('../Asseets/Other/News2/sniper20.png')}
                             alt="sniper" style={{display: "none"}}/>
                        <img name="sniper" className="slide" src={require('../Asseets/Other/News2/sniper40.png')}
                             alt="sniper" style={{display: "none"}}/>
                        <img name="sniper" className="slide" src={require('../Asseets/Other/News2/sniper60.png')}
                             alt="sniper" style={{display: "none"}}/>
                        <img name="sniper" className="slide" src={require('../Asseets/Other/News2/sniper80.png')}
                             alt="sniper" style={{display: "none"}}/>
                        <img name="sniper" className="slide" src={require('../Asseets/Other/News2/sniper100.png')}
                             alt="sniper" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("sniper")}>&#10095;</div>
                    </div>
                </details>
                <iframe className="frame"
                    src="https://www.youtube.com/embed/Y__tuBseokc"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share" allowFullScreen></iframe>
                <p className="newsPageText" style={{paddingBottom: 50}}>
                    Будьте готовы к длительным преследованиям с новой системой <span
                    className="word">выносливости</span>, позволяющей увеличить
                    запас
                    силы, ускорить восстановление и уменьшить потребление выносливости. Прокачивайте этот навык,
                    преодолевая
                    километры в виртуальном мире, и чувствуйте, как с каждым пробегом ваш персонаж становится только
                    более
                    выносливым и энергичным. Достигните вершины ваших возможностей и наслаждайтесь бесконечной
                    выносливостью, которая позволит вам исследовать мир без ограничений. Однако помните, что если вы
                    пренебрегаете отдыхом и ваш уровень энергии опускается до критического, это может сказаться на
                    здоровье
                    вашего персонажа, приводя к его истощению и потере жизненных сил.
                    <br/>
                    Желаем всем прекрасного настроения!
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News2;
