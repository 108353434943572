import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-20')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News20 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №20 (Работа "Бульдозерист")</h1>
                <p className="newsPageText">
                    Чтобы начать работу бульдозеристом, игроку необходимо достичь 150 уровня навыка шахтёра и получить
                    лицензию на управление специализированной техникой. После этого ваш персонаж готов приступить к
                    рабочему дню, садясь за руль бульдозера и направляясь к карьеру для загрузки камней и песка. Затем
                    необходимо перевезти нагрузку к бункеру разгрузки, где игрок сможет выгрузить добычу.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News20/1 1-min.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News20/1 2-min.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    Эта новая профессия не только предлагает возможность заработка игровой валюты для развития вашего
                    персонажа, но и открывает доступ к эксклюзивным предметам. Эти предметы могут быть как сохранены и
                    использованы в будущем, так и проданы или обменяны с другими игроками, открывая вам дополнительные
                    возможности в игровом мире.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("second")}>&#10094;</div>
                        <img name="second" className="slide" src={require('../Asseets/Other/News20/2 1-min.png')}
                             alt="screenshot"/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News20/2 2-min.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("second")}>&#10095;</div>
                    </div>
                    <span className="word">P.S.</span> В одной из следующих статей мы расскажем вам подробнее об
                    эксклюзивных предметах.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News20;
