import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-23')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News23 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №23 (Работа "Таксист")</h1>
                <p className="newsPageText">
                    Представляем вашему вниманию возможность начать карьеру водителя такси. Теперь каждый игрок может
                    стать частью живого ритма города, выбравшись за руль автомобиля из автопарка.
                    <br/>
                    <br/>
                    Что делает сервис таким уникальным? Мы предлагаем два варианта тарифов: эконом и бизнес. Экономичный
                    тариф подходит для тех, кто ценит разумные цены, а бизнес-класс предлагает роскошные автомобили и
                    дополнительные удобства для самых требовательных клиентов.
                    <br/>
                    <br/>
                    Одним из ключевых преимуществ нашего сервиса является наличие станций такси в каждом уголке города.
                    Это позволяет начать работу в месте, наиболее удобном для каждого игрока, не тратя времени на долгие
                    поиски.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News23/1 1.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News23/1 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News23/1 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    Заказ такси стал еще проще и удобнее благодаря приложению. Просто закажите такси через телефонное
                    приложение, и наш водитель немедленно отправится к вам. Вы всегда можете отслеживать статус вашего
                    заказа прямо в приложении, чтобы быть уверенными в том, что ваш транспорт уже в пути.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("second")}>&#10094;</div>
                        <img name="second" className="slide" src={require('../Asseets/Other/News23/3 1.png')}
                             alt="screenshot"/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News23/3 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News23/3 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News23/3 4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News23/3 5.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("second")}>&#10095;</div>
                    </div>
                    Во время поездки вы можете указать место, куда вы направляетесь, просто установив точку на карте.
                    Водитель немедленно отреагирует на ваш запрос и доставит вас туда, куда вам нужно. По завершении
                    поездки у вас будет возможность оценить сервис и оставить чаевые - ваше мнение имеет для сервиса
                    огромное значение.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News23/2.png')}
                         alt="screenshot"/>
                    Кроме того, для самых опытных водителей у нас есть особое предложение. Получив 100 навык работы
                    таксиста, вы получаете возможность управлять транспортом тарифа бизнес, что позволит вам не только
                    наслаждаться комфортом роскошных автомобилей, но и предоставить своим клиентам еще более высокий
                    уровень сервиса.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News23;
