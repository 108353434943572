import './Social.css';
import discord from "../../../Asseets/FirstBlock/Social/discord.svg"
import vk from "../../../Asseets/FirstBlock/Social/vk.svg"
import youtube from "../../../Asseets/FirstBlock/Social/youtube.svg"

function Social() {
    return (
        <div className="containerSocial">
            <a href="https://discord.gg/j8rUEtUM5F" target="_blank" rel="noreferrer">
                <img className="item" src={discord} alt="discord"/>
            </a>
            <a href="https://vk.com/5mpgg" target="_blank" rel="noreferrer">
                <img className="item" src={vk} alt="vk"/>
            </a>
            <a href="https://www.youtube.com/@5mpgg" target="_blank" rel="noreferrer">
                <img className="item" src={youtube} alt="youtube"/>
            </a>
        </div>
    );
}

export default Social;