import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-30')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News30 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №30 (Бизнес "Центр развлечений")</h1>
                <p className="newsPageText">
                    Центр развлечений предоставляет уникальную возможность создать лобби в понравившемся режиме, собрать
                    друзей или других игроков через открытое лобби, чтобы поучаствовать в мини-играх, не мешая основным
                    ролевым ситуациям на сервере.
                    <br/>
                    <br/>
                    На текущий момент Центр Развлечений предлагает три захватывающих режима мини-игр:
                    <br/>
                    <span className="word">Гонки</span> - Испытайте свои навыки вождения, создавая динамичные и захватывающие трассы.
                    <br/>
                     <span className="word">Бой насмерть</span> - Примите участие в интенсивных перестрелках, где каждый игрок сражается за выживание.
                    <br/>
                    <span className="word"> Дерби</span> - Участвуйте в хаотичных столкновениях на различных транспортных средствах.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News30/1 1.png')}
                         alt="screenshot"/>
                    <br/>
                    Каждый из этих режимов предоставляет игрокам возможность настроить лобби в соответствии с их
                    предпочтениями, что делает игровой процесс еще более увлекательным и разнообразным.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("third")}>&#10094;</div>
                        <img name="third" className="slide" src={require('../Asseets/Other/News30/2 1.png')}
                             alt="screenshot"/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News30/2 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News30/2 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News30/2 4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News30/2 5.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("third")}>&#10095;</div>
                    </div>
                    Особенное внимание заслуживает возможность владельцев бизнеса создавать свои собственные трассы для
                    гонок. Независимо от того, предпочитаете ли вы водный, воздушный или наземный транспорт, у вас есть
                    возможность создавать трассы под свои предпочтения. Более того, чем выше уровень вашего бизнеса, тем
                    больше трасс будет доступно для создания.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> В будущем список мини-игр будет пополняться.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News30;
