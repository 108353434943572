import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-11')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News11 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №11 (Бизнес - Магазин 24/7)</h1>
                <p className="newsPageText">
                    Магазин 24/7 предоставляет нашим игрокам простые, но важные элементы, направленные на улучшение их
                    игрового опыта. Здесь вы можете приобрести всё необходимое — от еды и воды до наборов для кемпинга.
                    <br/>
                    <br/>
                    Для обеспечения максимального комфорта игроков мы провели категоризацию всего ассортимента в
                    магазине. Теперь вам не придется тратить драгоценное время на поиск нужного предмета. Все товары
                    структурированы по категориям, обеспечивая легкий доступ и комфортный выбор.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News11/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News11/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    Особенностью нашего магазина является возможность устанавливать приобретенные предметы в мире. Эти
                    предметы не исчезнут, пока вы находитесь в их непосредственной близости. Таким образом, вы можете
                    создавать и поддерживать ролевые ситуации, внедряя в мир простые предметы и добавляя в него
                    дополнительные аспекты атмосферы.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News11/nature.png')}
                         alt="screenshot"/>
                    Важной чертой нашего функционала является возможность владельцу магазина заказывать каждую позицию
                    товаров отдельно. В случае отсутствия товара в наличии, потенциальный покупатель будет вынужден
                    обратиться в другие торговые точки.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("owner")}>&#10094;</div>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News11/owner.png')}
                             alt="screenshot"/>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News11/owner2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("owner")}>&#10095;</div>
                    </div>
                    Список доступных предметов будет обновляться, предоставляя вам возможности для улучшения вашего
                    путешествия.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News11;
