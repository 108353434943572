import './ButtonBetaTest.css';

function betaTest(){
    alert("К сожалению, запись на бета тестирование пока-что недоступна, следите за новостями в наших социальных  сетях!");
}
function ButtonBetaTest() {
    return (
        <div className="containerButtonBetaTest">
            <p className="buttonBetaTest" onClick={betaTest}> Бета-тест</p>
        </div>
    );
}

export default ButtonBetaTest;