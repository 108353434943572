import './FirstBlock.css';
import "../../Asseets/Fonts/stylesheet.css"
import RunningLine from "./RunningLine/RunningLine";
import Social from "./Social/Social";
import Character from "./Character/Character";
import Text from "./Text/Text"
import ButtonBetaTest from "./ButtonBetaTest/ButtonBetaTest";
import Down from "./Down/Down";

function FirstBlock() {
    return (
        <div className="firstBlock">
            <Social/>
            <RunningLine/>
            <Character/>
            <Text/>
            <ButtonBetaTest/>
            <Down/>
        </div>
    );
}

export default FirstBlock;