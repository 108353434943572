import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-17')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News17 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №17 (Бизнес - Автомастерская)</h1>
                <p className="newsPageText">
                    <iframe className="frame"
                            src="https://www.youtube.com/embed/EBoG3wz4yhM"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
                            allowFullScreen></iframe>
                    <br/>
                    Одной из ключевых особенностей нашей автомастерской является возможность тщательного ухода за вашим
                    автомобилем. Подверженные естественному износу, внутренние детали автомобиля могут постепенно терять
                    свою работоспособность, влияя на его общую производительность. Например, полностью поврежденный
                    двигатель приведет к невозможности завести автомобиль, а поврежденная коробка передач лишит вас
                    возможности даже начать движение.
                    <br/>
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News17/shop.png')}
                         alt="screenshot"/>
                    <br/>
                    Но это далеко не все. Мы предлагаем вам возможность установки баллонов с закисью азота, позволяющих
                    кратковременно увеличить скорость вашего автомобиля. Баллоны азота могут быть настроены в
                    соответствии с вашими предпочтениями, обеспечивая максимальное ускорение за счет ограниченного
                    времени работы. После каждого использования вам предстоит перезарядить баллоны в той же самой
                    автомастерской, где их приобрели.
                    <br/>
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News17/shop4.png')}
                         alt="screenshot"/>
                    <br/>
                    Кроме того, наши игроки теперь могут выбирать шины которые будут определять цвет дыма, исходящего
                    из-под колес, добавляя индивидуальности своему транспорту. Но, возможно, самая важная функция,
                    предоставляемая нашей автомастерской, – это возможность детальной настройки ходовой части
                    автомобиля. Настройте кастор, схождение, развал, привод и тормозное усилие так, чтобы ваш автомобиль
                    стал идеально подходящим для дрифта или гонок.
                    <br/>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News17/shop5.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News17/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News17/shop3.png')}
                             alt="screenshot"/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    <br/>
                    В нашей автомастерской каждая деталь имеет значение, каждая настройка приносит удовольствие от езды,
                    и каждое обслуживание делает ваш транспорт более превосходным.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News17/owner.png')}
                         alt="screenshot"/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News17;
