import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "shop2": 0,
    "shop3": 0,
    "shop4": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-18')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News18 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №18 (Бизнесы - Производства)</h1>
                <p className="newsPageText">
                    Производственные цепочки - это новых подход к управлению экономикой в игровом мире. Каждая цепочка
                    состоит из нескольких этапов, начиная от добычи ресурсов и заканчивая производством конечного
                    продукта. Первым примером такой цепочки является нефтяная промышленность.
                    <br/>
                    <br/>
                    На первом этапе владелец нефтяной скважины берет на себя ответственность за запуск процесса добычи
                    нефти и установку цены на продажу. Затем нефть поступает на нефтеперерабатывающий завод, где
                    происходит её обработка в различные виды топлива. Владелец завода имеет возможность выбирать типы
                    топлива для производства и поддерживать баланс между ними. Однако, владелец также может сделать
                    полный фокус на производстве одного предмета, вместо поддержки баланса, если это будет выгодно.
                    Наконец, конечные продукты доставляются на заправки, где владельцы бизнеса устанавливают цены и
                    осуществляют продажу клиентам.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News18/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News18/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News18/shop3.png')}
                             alt="screenshot"  style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News18/shop4.png')}
                             alt="screenshot"  style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    Однако, если нефтяные скважины исчерпаны или недоступны, игрокам всегда доступен экстренный вариант
                    импорта сырья через порт. Хотя это не самый выгодный способ, он призван решить возникшую ситуацию.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop2")}>&#10094;</div>
                        <img name="shop2" className="slide" src={require('../Asseets/Other/News18/shop5.png')}
                             alt="screenshot"/>
                        <img name="shop2" className="slide" src={require('../Asseets/Other/News18/shop6.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop2")}>&#10095;</div>
                    </div>
                    Приведенный пример - лишь одна из множества возможных производственных цепочек. У нас уже готовы и
                    другие цепочки, такие как деревообрабатывающая и строительная промышленность, а также производство
                    пищевых продуктов.
                    <br/>
                    <br/>
                    Важным отличием других цепочек является возможность добычи ресурсов непосредственно на месте.
                    Например, игроки могут заниматься добычей руды и камней в шахтах. Полученные камни можно продать
                    непосредственно на шахте или доставить на цементный завод, где владелец устанавливает цену на
                    покупку. Это открывает новые возможности для игроков увеличить свой доход от продажи базовых
                    ресурсов.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop3")}>&#10094;</div>
                        <img name="shop3" className="slide" src={require('../Asseets/Other/News18/shop7.png')}
                             alt="screenshot"/>
                        <img name="shop3" className="slide" src={require('../Asseets/Other/News18/shop8.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop3" className="slide" src={require('../Asseets/Other/News18/shop9.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop3")}>&#10095;</div>
                    </div>
                    То же самое относится и к лесорубам, которые могут добывать древесину. Они могут продать её на месте
                    добычи или отправиться на деревообрабатывающий завод для более выгодной сделки. Эта система
                    позволяет игрокам эффективно управлять своими ресурсами и находить наиболее выгодные пути их
                    монетизации.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop4")}>&#10094;</div>
                        <img name="shop4" className="slide" src={require('../Asseets/Other/News18/shop10.png')}
                             alt="screenshot"/>
                        <img name="shop4" className="slide" src={require('../Asseets/Other/News18/shop11.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop4" className="slide" src={require('../Asseets/Other/News18/shop12.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop4")}>&#10095;</div>
                    </div>
                    Новый функционал сервера позволяет игрокам не только углубиться в управление бизнесом, но и влиять
                    на экономическую среду игрового мира.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News18;
