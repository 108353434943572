import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

const News4 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №4 (Система банков и банкоматов)</h1>
                <p className="newsPageText">
                    У нас имеется <span className="word">три банка</span>, и каждый из них обладает физическим и
                    юридическим счетами для игроков.
                    <span className="word">Физический счет</span> включает в себя заработную плату с работы, фракций и
                    другие источники дохода.
                    <span className="word">Юридический счет</span> включает в себя доход от бизнесов, а также списание
                    средств при покупках расходных
                    материалов для бизнеса.
                    <br/>
                    <br/>
                    В каждом из банков предусмотрено <span className="word">пять уровней статуса аккаунта: Bronze, Silver, Gold, Platinum и
                    Diamond</span>. В зависимости от банка и уровня статуса аккаунта меняются условия обслуживания:
                    комиссии с
                    зарплаты с работы, комиссии с зарплаты фракции, комиссии с дохода бизнеса, комиссии с оплаты счетов,
                    комиссии при снятии наличных в банкоматах, комиссии при переводе денег, максимальные лимиты снятия
                    наличных и перевода денег, а также стоимость типа банковского аккаунта.
                    <br/>
                    <br/>
                    Важно отметить, что статус <span className="word">Bronze - это бесплатный тип аккаунта</span>,
                    доступный в каждом банке. Однако,
                    для получения привилегий более высоких уровней (Silver, Gold, Platinum, Diamond), будет необходимо
                    приобрести соответствующий статус аккаунта.
                    <br/>
                    <br/>
                    Каждый из банков и банкоматов имеет свой <span className="word">уникальный дизайн</span>.
                    <br/>
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News4/bank1.png')}
                         alt="screenshot"/>
                    <br/>
                    Взаимодействие с банкоматами предоставляет пользователям удобный доступ к информации о текущем
                    состоянии своего банковского аккаунта и актуальных бонусах. Кроме того, здесь можно осуществлять
                    пополнение как физического, так и юридического счетов, а также легко снимать деньги с обоих видов
                    счетов.
                    <br/>
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News4/bank2.png')}
                         alt="screenshot"/>
                    <br/>
                    Внутри банка открываются широкие возможности: начиная с открытия банковского аккаунта и ознакомления
                    с текущими бонусами, пользователь может легко пополнять <span
                    className="word">физический</span> и <span className="word">юридический</span> счет, а также
                    совершать снятие денег с обоих счетов. Важной функцией является возможность осуществления переводов
                    на физический счет в пределах этого банка, а также <span className="word">оплата</span> различных
                    счетов, таких как дом,
                    электричество, бизнес, транспорт и штрафы.
                    <br/>
                    <br/>
                    Дополнительные функции включают в себя возможность <span
                    className="word">изменения типа аккаунта</span>, что в свою очередь может
                    повлиять на комиссии и лимиты. Также имеется функция <span className="word">включения или отключения автопродления типа
                    аккаунта</span> для удобства пользователя. <span className="word">Прошлые операции</span> всегда
                    доступны для просмотра, обеспечивая
                    полный контроль над финансовыми операциями.
                    <br/>
                    <br/>
                    <span className="word">Выбор активного банка</span> для получения зарплаты с работы, зарплаты с
                    фракции или получения дохода с
                    бизнеса создает дополнительную связь между функционалом и пользователем, позволяя <span
                    className="word">адаптировать
                    условия обслуживания под конкретные потребности</span>.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> У некоторых пользователей возникали вопросы относительно нашего
                    визуального оформления. В
                    данной статье мы представили примеры дизайна, которые будут ожидать вас на релизе.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News4;
