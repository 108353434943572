import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "acc": 0,
    "jewelry": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-10')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News10 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №10 (Бизнесы "Магазин аксессуары" и "Ювелирный магазин")</h1>
                <p className="newsPageText">
                    Магазин аксессуаров стал настоящим источником стиля и индивидуальности для каждого игрока. Теперь вы
                    можете приобрести не только маски, рюкзаки и перчатки, но и разнообразные аксессуары, которые
                    выражают ваш уникальный стиль. Очки различных форм и цветов, браслеты на руки, простые часы или
                    разгрузочные пояса – наш ассортимент позволит вам создать неповторимый облик.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("acc")}>&#10094;</div>
                        <img name="acc" className="slide" src={require('../Asseets/Other/News10/shop.png')}
                             alt="screenshot"/>
                        <img name="acc" className="slide" src={require('../Asseets/Other/News10/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("acc")}>&#10095;</div>
                    </div>
                    В Ювелирном Магазине вы найдете исключительные украшения, добавляющие роскошь и элегантность вашему
                    облику. Здесь вы можете приобрести браслеты, часы, украшения на уши, цепи и другие предметы с
                    драгоценными металлами и камнями, чтобы придать вашему персонажу неповторимый вид и выразить свою
                    индивидуальность.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("jewelry")}>&#10094;</div>
                        <img name="jewelry" className="slide" src={require('../Asseets/Other/News10/shop3.png')}
                             alt="screenshot"/>
                        <img name="jewelry" className="slide" src={require('../Asseets/Other/News10/shop4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("jewelry")}>&#10095;</div>
                    </div>
                    Владельцы бизнесов будут следить за наличием каждого типа вещей в своем ассортименте, легко
                    заказывать их и выставлять наценку на продажу.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("owner")}>&#10094;</div>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News10/owner.png')}
                             alt="screenshot"/>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News10/owner2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("owner")}>&#10095;</div>
                    </div>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News10;
