import './Character.css';
import character from "../../../Asseets/FirstBlock/Character/michael.png"

function Character() {
    return (
        <div className="containerCharacter">
            <img className="characterImage" src={character} alt="character"/>
        </div>
    );
}

export default Character;