import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import News1 from "./News/news1";
import Home from "./home";
import NotFound from "./NotFound/notFound";
import News2 from "./News/news2";
import News3 from "./News/news3";
import News4 from "./News/news4";
import News5 from "./News/news5";
import News6 from "./News/news6";
import News7 from "./News/news7";
import News8 from "./News/news8";
import News9 from "./News/news9";
import News10 from "./News/news10";
import News11 from "./News/news11";
import News12 from "./News/news12";
import News13 from "./News/news13";
import News14 from "./News/news14";
import News15 from "./News/news15";
import News16 from "./News/news16";
import News17 from "./News/news17";
import News18 from "./News/news18";
import News19 from "./News/news19";
import News20 from "./News/news20";
import News21 from "./News/news21";
import News22 from "./News/news22";
import News23 from "./News/news23";
import News24 from "./News/news24";
import News25 from "./News/news25";
import News26 from "./News/news26";
import News27 from "./News/news27";
import News28 from "./News/news28";
import News29 from "./News/news29";
import News30 from "./News/news30";
import News31 from "./News/news31";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <Router>
          <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route exact path="/news-1" element={<News1/>} />
              <Route exact path="/news-2" element={<News2/>} />
              <Route exact path="/news-3" element={<News3/>} />
              <Route exact path="/news-4" element={<News4/>} />
              <Route exact path="/news-5" element={<News5/>} />
              <Route exact path="/news-6" element={<News6/>} />
              <Route exact path="/news-7" element={<News7/>} />
              <Route exact path="/news-8" element={<News8/>} />
              <Route exact path="/news-9" element={<News9/>} />
              <Route exact path="/news-10" element={<News10/>} />
              <Route exact path="/news-11" element={<News11/>} />
              <Route exact path="/news-12" element={<News12/>} />
              <Route exact path="/news-13" element={<News13/>} />
              <Route exact path="/news-14" element={<News14/>} />
              <Route exact path="/news-15" element={<News15/>} />
              <Route exact path="/news-16" element={<News16/>} />
              <Route exact path="/news-17" element={<News17/>} />
              <Route exact path="/news-18" element={<News18/>} />
              <Route exact path="/news-19" element={<News19/>} />
              <Route exact path="/news-20" element={<News20/>} />
              <Route exact path="/news-21" element={<News21/>} />
              <Route exact path="/news-22" element={<News22/>} />
              <Route exact path="/news-23" element={<News23/>} />
              <Route exact path="/news-24" element={<News24/>} />
              <Route exact path="/news-25" element={<News25/>} />
              <Route exact path="/news-26" element={<News26/>} />
              <Route exact path="/news-27" element={<News27/>} />
              <Route exact path="/news-28" element={<News28/>} />
              <Route exact path="/news-29" element={<News29/>} />
              <Route exact path="/news-30" element={<News30/>} />
              <Route exact path="/news-31" element={<News31/>} />
              <Route exact path="*" element={<NotFound/>} status={404}/>
          </Routes>
      </Router>
    </React.StrictMode>
);
