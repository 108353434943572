import React from 'react';
import './news.css';
import Card from "../Components/Footer/Cards/card";

const News1 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №1 (V-MP 2.0 | V-MP REBORN)</h1>
                <p className="newsPageText" style={{paddingBottom: 50}}>
                    Доброго времени суток, пришло время приоткрыть вам зановес по поводу <span className="word">V-MP</span> ака <span className="word">5MP.GG</span><br/><br/>
                    С вами на связи я, <span className="word">Skilloff</span>, более известен под RP никнеймом <span
                    className="word">Tony Richards</span>, уже почти <span className="word">3 года</span>, как
                    <span className="word"> V-MP</span> был закрыт.
                    И на протяжении этого времени, я получал сообщения по типу "А как зайти на V-MP", "А когда
                    открытие", также интерес не утихал и в соц сетях проекта.
                    Спустя где-то год-полтора после закрытия, у нас с <span className="word">Revan</span>'ом появилось
                    дикое желание возродить проект,
                    но открыться со старым модом и старым мультиплеером/клиентом мы себе не могли позволить, всё-таки,
                    если начинать сначала, то всё с чистого листа.
                    И вот спустя, полтора года усердной разработки, мы уже приближаемся к такому рубежу, где мы можем
                    понемногу начать рассказывать вам, что вам стоит ожидать
                    от нового игрового мода, нового мультиплеера и клиента.
                    Стоит упомянуть то, что мод и лаунчер написаны с нуля, учитывая многолетний опыт и новые технологии.
                    Мы хотим возродить ту самую атмосферу, которая была у нас в далеких <span
                    className="word">17-18</span> годах. <br/><br/>
                    Также не могу оставить без внимания нашу философию и перспективы, которые мы стремимся предоставить
                    вам. Наши уроки были горькими, когда мы предоставляли определенным игрокам преимущества перед
                    остальными, оставляя общение с сообществом на втором плане.
                    Мы помним, как долго ждали некоторые изменения, которые, казалось бы, могли бы быть внесены в
                    игровой мод.
                    Однако наступило <span className="word">время перемен</span>. Мы категорически <span className="word">против</span> агрессивного доната и отдаем предпочтение
                    развитию через взаимодействие с нашей <span className="word">аудиторией</span>, а не путем выделения отдельных игроков. Мы за
                    общение, а не самодурство.
                    Пришло время того, чтобы сделать выбор в пользу вас, наших игроков. Наши обновления будут
                    формироваться с учетом ваших предпочтений, выраженных в голосованиях в наших социальных сетях.
                    Мы верим в создание сервера, который будет воплощать ваши ожидания и делать игру увлекательной для
                    каждого.
                    <br/><br/>
                    Что касается мультиплеера, мы не будем нарушать наших традиций, поэтому вы сможете играть с <span
                    className="word">любой </span>
                    версии GTA5. <br/><br/>
                    Поэтому в ближайшее время, мы уже начнем публиковать фрагменты функционала, а что бы не пропустить
                    новости, вы
                    можете(обязаны) подписаться на наши соц сети. <br/><br/>
                    <span className="word">P. S</span> Pablo Falcone (Михаил) не имеет никакого отношения к <span className="word">5mp.gg</span>
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News1;
