import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "big": 0,
    "small": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-3')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News3 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №3 (Меню выбора персонажа)</h1>
                <p className="newsPageText">
                    Мы внесли индивидуальные изменения в раздел выбора персонажа, зависящие от участия персонажа в
                    определенной фракции. Каждая сцена представляет собой не просто статичное изображение ситуации, а
                    динамичную анимированную сцену, воплощенную внутри игры
                    <br/>
                    Если ваш персонаж не имеет работы и не обладает собственным жильем, то его окружение соответствует
                    этим обстоятельствам.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News3/homeless.png')}
                         alt="screenshot"/>
                    <br/>
                    В случае наличия жилья, естественно, ваш персонаж будет находиться дома, отражая успехи своей жизни
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News3/house.png')}
                         alt="screenshot"/>
                    <br/>
                    То же самое касается всех активных фракций на сервере, где каждая из них будет отображать свою
                    собственную специфику
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("big")}>&#10094;</div>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/police.png')}
                             alt="screenshot"/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/mafia.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/ems.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/army.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/government.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/gang.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/dea.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/biker.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/news.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="big" className="slide" src={require('../Asseets/Other/News3/dmv.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("big")}>&#10095;</div>
                    </div>
                    Как вы, вероятно, заметили по предоставленным выше скриншотам, в меню выбора персонажа предоставлена
                    обширная информация о каждом герое, включая важные предупреждения, вынесенные администрацией.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("small")}>&#10094;</div>
                        <img name="small" className="slide" src={require('../Asseets/Other/News3/banBig.png')}
                             alt="screenshot"/>
                        <img name="small" className="slide" src={require('../Asseets/Other/News3/banSmall.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("small")}>&#10095;</div>
                    </div>
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News3;
