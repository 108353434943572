import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-26')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News26 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №26 (Работа "Дорожный рабочий")</h1>
                <p className="newsPageText">
                    Чтобы приступить к работе дорожного рабочего, игроку необходимо достичь 150 уровня навыка в ветке
                    строителя и получить лицензию на специальный транспорт. Получив лицензию, вы сможете начать свою
                    рабочую смену, взяв транспорт и отправившись к указанной на GPS метке, которая приведёт вас к месту,
                    требующему ремонта.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News26/1 1.png')}
                         alt="screenshot"/>
                    По прибытии на точку ремонта, вам нужно будет правильно разместить рабочий транспорт в соответствии
                    с указаниями метки. После этого вокруг рабочей зоны автоматически устанавливается перекрытие для
                    обеспечения безопасности рабочего. Перекрытие является статичным и не может быть сбито другими
                    игроками, что гарантирует вашу безопасность во время выполнения работ.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News26/2 1.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News26/2 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News26/2 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    После завершения работы на текущей точке, на GPS поступает информация о следующем месте, требующем
                    ремонта. Вы можете продолжить выполнение заданий или завершить смену, вернувшись на базу к NPC
                    работодателю.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News26/3 1.png')}
                         alt="screenshot"/>
                    Помимо заработка, работа строителем предоставляет возможность получения уникальных предметов,
                    которые можно оставить себе или продать другим игрокам. Это добавляет дополнительный стимул для
                    выполнения заданий и вовлечения в игровой процесс.
                    <br/>
                    <br/>
                    P.S. Мини-игра является временной заглушкой для удобства разработки.
                    <br/>
                    P.P.S. Об уникальных предметах мы вам обязательно расскажем, но чуть позже.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News26;
