import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-12')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News12 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №12 (Бизнес - Салон красоты)</h1>
                <p className="newsPageText">
                    В салоне красоты, вы сможете трансформировать внешний вид вашего персонажа, подчеркнув его
                    индивидуальность и стиль.
                    <br/>
                    <br/>
                    Изменения внешности включают в себя прическу, состояние бороды, оттенок губ, наличие растительности
                    на теле и макияж. При выборе прически, вы даже можете определить вторичный оттенок, придающий вашему
                    персонажу неповторимость и уникальность.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News12/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News12/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News12/shop3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News12/shop4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    Владельцы салона красоты имеют возможность заказывать различные виды расходных материалов,
                    необходимых для бесперебойного функционирования своего бизнеса. Кроме того, они могут устанавливать
                    наценки на предоставляемые услуги в соответствии с их усмотрением и текущими экономическими
                    условиями.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News12/owner.png')}
                         alt="screenshot"/>
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News12;
