import './footer.css';
import Card from "./Cards/card";
import Up from "./Up/Up";

function Footer() {
    return (
        <div className="footer">
            <Card/>
            <Up/>
        </div>
    );
}

export default Footer;