import './SecondBlock.css';
import News from "./News/News";

function SecondBlock() {
    return (
        <div className="secondBlock">
            <News/>
        </div>
    );
}

export default SecondBlock;