var elements = [];
var index = 0;
var canScroll = true;

setTimeout(loadElements, 50)
setInterval(() => {
    canScroll = true
}, 1000)
document.getElementById('root').addEventListener("wheel", (event) => {
    if(window.location.pathname !== '/')
        return;

    if (window.screen.width <= 1000)
        return;

    event.stopPropagation();
    event.preventDefault();
    if (canScroll === false)
        return;

    canScroll = false;
    if (event.deltaY > 0)
        index++;
    else
        index--;
    if (index < 0)
        index = 0;
    if (index > 2)
        index = 2;

    goToElement()
});
const scroll = function setScrollIndex(value) {
    index = value;
}

function goToElement() {
    if(window.location.pathname !== '/')
        return;

    if (elements[index]) {
        elements[index].scrollIntoView();
    }
}

function loadElements() {
    if(window.location.pathname !== '/')
        return;

    elements = [document.getElementsByClassName('firstBlock')[0],
        document.getElementsByClassName('secondBlock')[0],
        document.getElementsByClassName('footer')[0]]

    if(!elements[0])
        return;

    elements[0].scrollIntoView();
}

export default scroll;