import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-22')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News22 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №22 (Работа "Водитель автобуса")</h1>
                <p className="newsPageText">
                    После успешного устройства на работу водителем автобуса вашему персонажу предстанет увлекательная
                    смена. Вам будет предложено выбрать маршрут, который вы хотели бы освоить. Изначально открыты лишь
                    маршруты внутри города, но с набором определенного уровня навыка вас ждут междугородние путешествия,
                    обещающие не только большее расстояние, но и более щедрые вознаграждения.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News22/1 1.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News22/1 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News22/1 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    Каждый город обладает своими уникальными внутригородскими маршрутами, расширяя ваш кругозор и
                    поддерживая интерес к развитию вашего персонажа. Не забывайте, что каждая смена приносит игроку
                    уникальные предметы, которые можно использовать в будущем или выгодно продать другим игрокам.

                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("second")}>&#10094;</div>
                        <img name="second" className="slide" src={require('../Asseets/Other/News22/2 1.png')}
                             alt="screenshot"/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News22/2 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("second")}>&#10095;</div>
                    </div>
                    <span className="word">P.S.</span> Немного позже мы вам расскажем об уникальных предметах и для чего они нужны.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News22;
