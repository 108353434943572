import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-28')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News28 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №28 (Работа "Электрик")</h1>
                <p className="newsPageText">
                    Для начала работы электриком, игроку необходимо достичь 500 уровня навыка в ветке строительства и
                    получить лицензию на управление специальным транспортом. Это гарантирует, что только
                    квалифицированные специалисты смогут выполнять ответственные задания, связанные с электричеством.
                    <br/>
                    <br/>
                    Начало смены и транспорт:
                    <br/>
                    После начала рабочей смены, игроку будет предоставлен уникальный автомобиль электрика, оснащенный
                    подвижной корзиной для работы на высоте.
                    Использование GPS:
                    <br/>
                    При посадке в автомобиль, на GPS будет отмечена точка, куда необходимо отправиться для устранения
                    неисправности в электросети.
                    <br/>
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News28/1 1.png')}
                         alt="screenshot"/>
                    <br/>
                    По прибытии на место, игроку потребуется установить рабочий автомобиль в указанной области. Это
                    важно для обеспечения безопасности и удобства проведения ремонтных работ.
                    <br/>
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News28/2 1.png')}
                         alt="screenshot"/>
                    <br/>
                    Затем необходимо залезть в корзину и поднять её на нужную высоту, используя стрелки на клавиатуре.
                    Поместив корзину в позицию маркера, игрок должен успешно завершить мини-игру для выполнения ремонта
                    электросети.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("third")}>&#10094;</div>
                        <img name="third" className="slide" src={require('../Asseets/Other/News28/3 1.png')}
                             alt="screenshot"/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News28/3 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("third")}>&#10095;</div>
                    </div>
                    После завершения починки, корзину нужно опустить в исходное положение. Игрок может либо продолжить
                    работу, следуя по следующим меткам GPS, либо вернуться на базу и закончить смену.
                    Этот функционал предоставляет игрокам возможность разнообразить свой игровой опыт, окунуться в
                    профессиональную деятельность электрика и испытать новые захватывающие задачи. Присоединяйтесь к нам
                    на сервере и попробуйте свои силы в новой роли!
                    <br/>
                    <iframe className="frame"
                            src="https://www.youtube.com/embed/b4BTL6Dxp80"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;web-share"
                            allowFullScreen></iframe>
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Мини-игра является временной заглушкой для удобства
                    разработки.<br/>
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News28;
