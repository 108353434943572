import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

const News5 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №5 (Бизнес "Каршеринг")</h1>
                <p className="newsPageText">
                    Игроки могут воспользоваться возможностью арендовать <span className="word">различные виды транспорта</span>,
                    предоставленные
                    пятью бизнесами, которые распределены по всей игровой карте. Пользователи могут осуществлять аренду
                    через <span className="word">диалог с NPC, мобильное приложение или телефонные будки</span>, что
                    придает этой опции <span className="word">высокую
                    гибкость и удобство в использовании</span>.
                    <br/>
                    <br/>
                    Перед тем, как принять решение о том, какой транспорт арендовать, игроки получают возможность
                    ознакомиться с подробным меню, содержащим <span className="word">название, изображение, стоимость залога, арендную плату в
                    час, уровень топлива, пробег и текущее состояние</span> выбранного транспортного средства.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News5/carsharing.png')}
                         alt="screenshot"/>
                    <br/>
                    Особенностью системы является <span className="word">возврат залога</span>, который непосредственно
                    зависит от состояния
                    транспорта после аренды. В случае, если транспорт не подвергся повреждениям, полная сумма залога
                    возвращается арендатору. В случае обнаружения повреждений, из суммы залога вычитается стоимость
                    ремонта, а оставшаяся сумма возвращается на счет арендатора.
                    <br/>
                    <br/>
                    <span className="word">Собственники бизнесов получают привилегию выбора транспорта для каршеринга и установки цены за час
                    аренды</span>. Залоговая часть рассчитывается автоматически, что обеспечивает удобство в управлении.
                    Транспорт, подвергшийся серьезным повреждениям или находящийся с недостаточным количеством
                    топлива/заряда, автоматически исключается из списка доступного для аренды, за исключением случаев,
                    когда запрос поступает от владельца
                    <br/>
                    <br/>
                    В момент аренды транспорта он будет <span className="word">доставлен максимально близко к игроку и отмечен на игровой
                    карте</span>, с учетом специфики каждого типа транспорта: автомобили - к ближайшей дороге, лодки - к
                    ближайшему берегу, а самолеты/вертолеты - к аэродрому, обеспечивая игрокам <span className="word">максимальное удобство и
                    эффективность использования этой возможности</span>.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Для новичков, чей уровень не превышает пятый, предусмотрено
                    возмещение полной стоимости залога. Кроме того, важно отметить, что новички, арендующие транспорт,
                    не несут ответственности за возможные повреждения транспортных средств, что предоставляет им
                    дополнительное удобство на начальном этапе игры. При завершении аренды транспорта любые возможные
                    повреждения, причиненные новичком, аннулируются.
                    <br/>
                    <br/>
                    <span className="word">P.S.S</span> Итоговый дизайн меню аренды автомобилей в каршеринге будет
                    отличаться от представленного в данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News5;
