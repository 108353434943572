import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

const News19 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №19 (Работа "Шахтер")</h1>
                <p className="newsPageText">
                    Шахтерская работа на нашем сервере стала еще более увлекательной и разнообразной благодаря внедрению
                    нового функционала. Теперь каждый игрок, занимающийся добычей ресурсов, может насладиться улучшенным
                    игровым процессом и большими возможностями для заработка.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News19/img1.png')}
                         alt="screenshot"/>
                    Каждая смена шахтера становится захватывающим приключением, ведь теперь игроки могут добывать
                    широкий спектр материалов - от обычного камня до блестящих алмазов. Весьма привлекательно, не правда
                    ли? Но это еще не все! После тяжелого труда игрокам предоставляется возможность либо продать свои
                    ресурсы на шахте, либо оставить их себе на случай будущих нужд. Ведь кто знает, когда понадобятся
                    ресурсы, добытые с таким трудом?
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News19/img2.png')}
                         alt="screenshot"/>
                    Однако камни, несмотря на свою простоту, также имеют свою ценность. Игроки могут продавать их
                    непосредственно на шахте, но для тех, кто стремится к большему, существует возможность получить
                    более выгодную сделку на цементном заводе. Единственное условие - добраться до него самому.
                    <br/>
                    <br/>
                    Таким образом, новый функционал не только расширяет игровой опыт шахтеров, но и предоставляет им
                    больше возможностей для заработка и развития в игровом мире.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News19;
