import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-8')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News8 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №8 (Бизнес "Мебельный магазин")</h1>
                <p className="newsPageText">
                    Ваш дом - это не просто место, где вы живете, это ваше пространство, выражающее ваш стиль и комфорт.
                    И теперь, с новым функционалом нашего сервера, мы предоставляем вам уникальную возможность
                    превратить ваш дом в настоящий уголок уюта и стиля.
                    <br/>
                    <br/>
                    Мебельные магазины становятся невероятным источником возможностей для улучшения вашего жилища.
                    Выберите мебель, которая отражает ваш вкус и стиль. Наш ассортимент включает в себя не только
                    обычную мебель, но и функциональные предметы, которые добавят удобство и практичность вашему
                    пространству.

                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News8/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News8/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News8/shop3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    <br/>
                    После того, как вы сделали свой выбор в мебельном магазине, предстоит решить, как получить свои
                    покупки. У нас есть два варианта для вашего удобства:
                    <br/>
                    <br/>
                    <ul style={{textAlign:"left"}}>
                        <li>
                            <span className="word">Самостоятельная доставка:</span> Если у вас есть свой транспорт,
                            вам
                            предоставляется возможность доставить
                            мебель самостоятельно. Это отличный способ удовлетворить свою независимость и получить
                            удовольствие
                            от личной организации доставки.
                        </li>
                        <li>
                            <span className="word">Доставка транспортной компанией:</span> Если у вас нет собственного
                            транспорта или вы предпочитаете не
                            беспокоиться о логистике, транспортная компания готова взять на себя доставку. Просто оформите
                            заказ, и ваша мебель будет доставлена.
                            Мебельный магазин активно сотрудничает с деревообрабатывающим предприятием, обеспечивая устойчивое
                            производство. Кроме того, у владельца бизнеса есть возможность заказа материалов из порта,
                            предоставляя альтернативный и более доступный вариант.
                        </li>
                    </ul>
                    <br/>
                    Меню настроек для владельца бизнеса
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("owner")}>&#10094;</div>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News8/owner.png')}
                             alt="screenshot"/>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News8/owner2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("owner")}>&#10095;</div>
                    </div>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News8;
