import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-21')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News21 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №21 (Работа "Грузчик")</h1>
                <p className="newsPageText">
                    Ветка работ грузчика начинается с портового грузчика. Это первый шаг на пути к профессионализму.
                    Ваша задача - разгружать портовые суда на берегу. Хоть эта работа кажется простой, она необходима
                    для получения необходимых навыков и опыта.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News21/1 1.png')}
                         alt="screenshot"/>
                    Следующим шагом после портового грузчика становится работа на вилочном погрузчике. Для устройства на
                    эту работу вам потребуется 150 скиллов грузчика и лицензия на специальный транспорт. Ваша задача -
                    перевозить грузы из временных складов до грузового поезда или грузового прицепа фургона.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News21/2 1.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News21/2 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    Третья и самая престижная работа в этой цепочке - работа на погрузчике контейнеров. Для устройства
                    на эту позицию вам потребуется 500 скиллов в ветке грузчика и соответствующая лицензия. Ваша главная
                    задача - перевозить контейнеры с грузового судна на временные склады контейнеров.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("second")}>&#10094;</div>
                        <img name="second" className="slide" src={require('../Asseets/Other/News21/3 1.png')}
                             alt="screenshot"/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News21/3 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("second")}>&#10095;</div>
                    </div>
                    Каждая следующая работа в этой цепочке предоставляет вам возможность зарабатывать больше. Кроме
                    того, каждая работа дает уникальные предметы, которые вы можете использовать в личных целях,
                    продавать или обменивать с другими игроками.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News21;
