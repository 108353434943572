import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-9')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News9 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №9 (Бизнес "Амму-нация")</h1>
                <p className="newsPageText">
                    Амму-Нация предоставляет игрокам возможность приобретения разнообразного арсенала оружия,
                    модификаций и патронов. Однако, следует помнить, что все товары в ассортименте подлежат утверждению
                    и контролю со стороны властей, которые определяют, какой список оружия доступен для приобретения.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News9/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News9/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    В дополнение к возможности покупки оружия, Амму-Нация предлагает уникальный тир, где игроки могут
                    отточить свои навыки стрельбы на различных типах оружия. Каждая сессия в тире предполагает
                    определенную плату. Более того, на тире ведется учет и формируются таблицы лидеров за последнюю
                    неделю и месяц, стимулируя соревновательный дух среди участников.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News9/shop3.png')}
                         alt="screenshot"/>
                    Владелец бизнеса имеет возможность заказывать все доступные типы оружия, модификаций и патронов для
                    своих нужд. Однако, стоит учитывать, что в соответствии с правилами, установленными правительством,
                    запрещенное оружие и модификации недоступны для покупки в нашей амму-нации.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("owner")}>&#10094;</div>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News9/owner.png')}
                             alt="screenshot"/>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News9/owner2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("owner")}>&#10095;</div>
                    </div>

                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News9;
