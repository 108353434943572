import './Down.css';
import image from '../../../Asseets/FirstBlock/Down/down.svg'
import scroll from "../../../scroll";


function goToBot() {
    scroll(3)
    var element= document.getElementsByClassName('footer')[0];
    element.scrollIntoView()
}

function Down() {
    return (
        <a className="containerDown" onClick={goToBot}>
            <img className="downItem" src={image} alt={"down"}/>
            <div className="downItem downText">ВНИЗ</div>
        </a>
    );
}

export default Down;