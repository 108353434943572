import './card.css';

function Card() {
    return (
        <div className="containerCards">
            <div className="footerCard">
                <div className="footerCardVk"></div>
                <p className="footerCardText">Все главные новости проекта</p>
                <a href="https://vk.com/5mpgg" className="footerCardButton">Подписаться</a>
            </div>
            <div className="footerCard">
                <div className="footerCardYoutube"></div>
                <p className="footerCardText">Видеообзоры обновлений сервера</p>
                <a href="https://www.youtube.com/@5mpgg" className="footerCardButton">Подписаться</a>
            </div>
            <div className="footerCard">
                <div className="footerCardDiscord"></div>
                <p className="footerCardText">Канал для общения с игроками</p>
                <a href="https://discord.gg/j8rUEtUM5F" className="footerCardButton">Подписаться</a>
            </div>
        </div>
    );
}

export default Card;