import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-14')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News14 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №14 (Бизнес - Заправки)</h1>
                <p className="newsPageText">
                    Представляем вашему вниманию заправки, которые преображают наше путешествие по городу, морю и небу.
                    В рамках системы топлива было внедрено разделение топлива на категории, соответствующие различным
                    видам транспорта.
                    <br/>
                    <br/>
                    Для наземного транспорта предусмотрены четыре типа топлива: Regular87, Plus89, Supreme92 и Дизель.
                    Кроме того, для электротранспорта доступны зарядные станции, обеспечивающие поддержание заряда
                    вашего электромобиля в отличном состоянии. Владельцы лодок могут заправляться на специальных
                    станциях, использующих топливо Regular87, которое также доступно на наземных заправках.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News14/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News14/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News14/shop3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News14/shop4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    Что касается воздушного транспорта, то для него было разработано специальное Авиационное топливо,
                    гарантирующее оптимальную работу двигателей. Все виды топлива производятся и доставляются до
                    заправочных станций по запросу владельцев бизнеса, при этом зарядные станции электротранспорта
                    являются исключением.
                    <br/>
                    <br/>
                    Владельцы бизнеса имеют возможность устанавливать наценки, улучшать свой бизнес увеличивая
                    вместимость резервуаров для топлива. Кроме того, они могут заказывать топливо для заправочных
                    станций у нефтеперерабатывающих предприятий. Важно отметить, что эти предприятия также могут влиять
                    на общую экономику игрового мира путем установки собственных наценок.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News14/owner.png')}
                         alt="screenshot"/>
                    Нефтеперерабатывающие предприятия играют ключевую роль в цепочке поставок топлива, и мы с
                    нетерпением ждем возможности рассказать вам о них более подробно в будущих статьях.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News14;
