import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-29')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News29 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №29 (Работа "Дальнобойщик")</h1>
                <p className="newsPageText">
                    <br/>
                    Лицензия и устройство на работу
                    <br/>
                    Для того чтобы стать дальнобойщиком, вам потребуется лицензия на управление грузовым транспортом.
                    Получив лицензию, вы сможете приступить к работе. Водители могут использовать как личные грузовики и
                    прицепы, так и арендовать их, если собственного транспорта нет.
                    <br/>
                    <br/>
                    Начальные условия и развитие
                    <br/>
                    Начинающие дальнобойщики получат доступ к простому грузовику с базовой вместимостью. Со временем, по
                    мере улучшения навыков, станет возможным использование более вместительных и мощных транспортных
                    средств.
                    <br/>
                    <br/>
                    Работа со складами
                    <br/>
                    На складах для дальнобойщиков представлено меню с товарами, доступными для покупки и продажи.
                    Ассортимент и цены периодически обновляются, чтобы обеспечить разнообразие и динамичность маршрутов.
                    Ваша задача — изучить склады и их ассортимент, чтобы проложить наиболее выгодный маршрут. Для этого
                    можно лично объехать несколько складов или взаимодействовать с другими дальнобойщиками через
                    голосовую или текстовую рацию.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News29/1 1.png')}
                         alt="screenshot"/>
                    <br/>
                    Требования к транспорту
                    <br/>
                    Для каждого товара на складах существуют свои требования к грузовикам и прицепам. Не все товары
                    можно загрузить в самый обычный грузовик, что заставляет дальнобойщиков выбирать подходящий
                    транспорт для каждого конкретного задания.
                    <br/>
                    <br/>
                    Выбор и перевозка товаров
                    <br/>
                    При выборе товара для перевозки вам будет предложен подходящий транспорт или прицеп, а также указана
                    стоимость за единицу товара, из разницы которой вы будете получать доход. Оплатив товар, вы увидите
                    маркер для погрузки. Вам потребуется поставить транспорт или прицеп в указанном на маркере
                    направлении для погрузки товара.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News29/2 1.png')}
                         alt="screenshot"/>
                    <br/>
                    Доставка и разгрузка
                    <br/>
                    После погрузки направляйтесь на другой склад, где есть тот же товар, чтобы продать его по выгодной
                    цене. Прибыв на место, вы сможете указать количество товара для разгрузки и установить транспорт или
                    прицеп в указанном направлении для завершения процесса.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("third")}>&#10094;</div>
                        <img name="third" className="slide" src={require('../Asseets/Other/News29/3 1.png')}
                             alt="screenshot"/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News29/3 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("third")}>&#10095;</div>
                    </div>
                    <br/>
                    Заказы от владельцев бизнесов
                    <br/>
                    Помимо основных заданий, дальнобойщики могут брать заказы от владельцев бизнесов для пополнения
                    ассортимента. Дальнобойщикам также доступна доставка купленной мебели от магазина до домов игроков,
                    что добавляет разнообразие в рутинные задачи.
                    <br/>
                    <br/>
                    Простые заказы
                    <br/>
                    Для игроков, которые не хотят погружаться во все механики складов или считают их слишком сложными,
                    доступна возможность брать простые заказы из точки в точку. Это позволяет быстро заработать и при
                    этом не заморачиваться на деталях логистики.
                    <br/>
                    <br/>
                    Выгода от доставки со склада
                    <br/>
                    Доставлять заказы со складов намного выгоднее, чем пользоваться альтернативным методом простых
                    заказов. Продуманная логистика и выбор правильных маршрутов могут значительно увеличить ваш доход и
                    принести дополнительное удовлетворение от выполненной работы.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News29;
