import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-7')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News7 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №7 (Бизнес "Магазин одежды")</h1>
                <p className="newsPageText">
                    Магазины одежды на нашем сервере делятся на три класса: бюджетный, премиум и элитный. Это позволяет
                    игрокам выбирать соответствующие своим предпочтениям и возможностям варианты одежды.
                    <br/>
                    <br/>
                    Помимо обычной возможности выбора головных уборов, верхней одежды, штанов и обуви, теперь у вас есть
                    шанс приобрести костюмы - заготовленные сеты одежды. Инвентарь вашего персонажа теперь обладает
                    специальным слотом под костюмы. Активируя костюм, вы блокируете другие слоты одежды (головные уборы,
                    верхнюю одежду, штаны и обувь), создавая уникальный облик, подчеркивающий ваш стиль.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News7/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News7/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News7/shop3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    <br/>
                    Мы также не забыли о возможности делать приятные сюрпризы. Теперь игроки могут приобретать элементы
                    одежды для персонажей противоположного пола, расширяя ассортимент и возможности подарков в игре.
                    <br/>
                    <br/>
                    Для владельцев бизнеса предусмотрены специальные инструменты управления. Они могут заказывать все
                    четыре типа одежды, костюмы и управлять складом, увеличивая его вместимость по своему усмотрению.
                    Также им
                    доступна возможность установки наценки на товары.
                    <br/>
                    <br/>
                    Меню настроек для владельца бизнеса
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("owner")}>&#10094;</div>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News7/owner.png')}
                             alt="screenshot"/>
                        <img name="owner" className="slide" src={require('../Asseets/Other/News7/owner2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("owner")}>&#10095;</div>
                    </div>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News7;
