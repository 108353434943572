import FirstBlock from "./Components/FirstBlock/FirstBlock";
import SecondBlock from "./Components/SecondBlock/SecondBlock";
import Footer from "./Components/Footer/footer";
import React from "react";
import "./scroll"
import './index.css';
import 'bootstrap/dist/css/bootstrap.css'

function Home() {
    return (
        <div>
            <FirstBlock/>
            <SecondBlock/>
            <Footer/>
        </div>
    );
}

export default Home;