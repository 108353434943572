import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "shop2": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-15')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News15 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №15 (Бизнес - Los Santos Customs)</h1>
                <p className="newsPageText">
                    С новым функционалом Los Santos Customs вы получаете доступ к широкому спектру возможностей, чтобы
                    придать вашему автомобилю неповторимый вид. Теперь у вас есть возможность не просто менять цвет
                    вашего транспорта, а использовать RGB палитру для создания собственного уникального оттенка, а также
                    воспользоваться перламутровыми оттенками для добавления изысканного блеска.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News15/shop2.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News15/shop4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    Кроме того, вы можете насладиться установкой неона, каркаса безопасности, бамперов, спойлеров, а
                    также изменением стиля номерного знака, глушителя, решётки радиатора, капота, крыши, клаксона,
                    дисков и тонировки. Мы призываем вас исследовать эти возможности и преобразить ваш автомобиль в
                    уникальное произведение искусства.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop2")}>&#10094;</div>
                        <img name="shop2" className="slide" src={require('../Asseets/Other/News15/shop.png')}
                             alt="screenshot"/>
                        <img name="shop2" className="slide" src={require('../Asseets/Other/News15/shop3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop2")}>&#10095;</div>
                    </div>
                    Кроме того, владелец бизнеса, стоящего за Los Santos Customs, обеспечивает постоянное обновление
                    ассортимента расходных материалов. Владелец также может настраивать наценку за выполнение работ
                    мастерской.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News15/owner.png')}
                         alt="screenshot"/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News15;
