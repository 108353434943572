import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-27')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News27 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №27 (Работа "Лесоруб")</h1>
                <p className="newsPageText">
                    Чтобы начать работу на лесозаготовке, первым делом вам потребуется приобрести топор. Топор можно
                    купить у специального NPC работодателя, который находится на локации лесозаготовки. Этот инструмент
                    станет вашим основным помощником в нелегком труде.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News27/1 1.png')}
                         alt="screenshot"/>
                    <br/>
                    Процесс рубки дерева разделен на два этапа:<br/><br/>
                    <span className="word">Первый этап</span>: Возьмите топор в руки, подойдите к дереву и начните
                    рубить его. После нескольких ударов
                    дерево упадет на землю. <br/><br/>
                    <span className="word">Второй этап</span>: Далее вам потребуется разделить срубленное дерево на
                    четыре части. Для этого подойдите
                    к упавшему дереву и продолжите работу топором.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News27/2 1.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News27/2 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    На нашем сервере доступны три локации для лесозаготовки, каждая из которых предлагает разные виды
                    деревьев и, соответственно, разную стоимость готового материала. Вид дерева и его стоимость зависят
                    от выбранной локации:<br/>
                    -<span className="word"> Лесозаготовка 1</span>: Древесина дуба, стоимость материала высокая.<br/>
                    -<span className="word"> Лесозаготовка 2</span>: Древесина березы, стоимость материала средняя.<br/>
                    -<span className="word"> Лесозаготовка 3</span>: Древесина ясеня, стоимость материала низкая.<br/>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("second")}>&#10094;</div>
                        <img name="second" className="slide" src={require('../Asseets/Other/News27/3 1.png')}
                             alt="screenshot"/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News27/3 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News27/3 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("second")}>&#10095;</div>
                    </div>
                    После завершения процесса рубки и разделения дерева на части, вы получите материал, который можно
                    продать несколькими способами:<br/><br/>
                    - <span className="word">NPC Работодателю</span>: Вы можете продать полученный материал NPC
                    работодателю на месте лесозаготовки. Это
                    удобный способ, если вам необходимо быстро получить деньги.<br/><br/>
                    - <span className="word">Деревообрабатывающее Производство</span>: За более выгодную стоимость вы
                    можете продать материал на
                    деревообрабатывающем производстве. Этот способ потребует от вас дополнительных усилий, но принесет
                    большую прибыль. (<span className="word">ВАЖНО</span>: цена зависит от владельца производства)<br/>
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("third")}>&#10094;</div>
                        <img name="third" className="slide" src={require('../Asseets/Other/News27/4 1.png')}
                             alt="screenshot"/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News27/4 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("third")}>&#10095;</div>
                    </div>
                    <span className="word">P.S.</span> Мини-игра является временной заглушкой для удобства разработки.<br/>
                    <span className="word">P.P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного
                    в данной статье.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News27;
