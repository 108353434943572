import React from 'react';
import './notFound.css';

const NotFound = () => {
    return (
        <div className="notFound">
           <div className="center">
               <div className="logo"></div>
               <div className="text">
                   Error 404
                   <br/>
                   Woops. Looks like this page doesn't exist.
                   <br/>
                   <a href="/">Back to home</a>
               </div>
           </div>
        </div>
    );
};

export default NotFound;