import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-31')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News31 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №31 (Система крафта)</h1>
                <p className="newsPageText">
                    Собирая предметы на работах, обмениваясь с другими игроками или приобретая на аукционе, вы сможете
                    использовать их для крафта. Создаваемые предметы могут предоставить особые возможности или служить
                    ежедневными расходными материалами, что добавит еще больше глубины и интерактивности в игровой
                    процесс.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News31/1 1.png')}
                         alt="screenshot"/>
                    Для начала крафта вам потребуется приобрести верстак для дома, который можно найти в мебельном
                    магазине. Однако одного верстака недостаточно — для некоторых крафтов потребуются специальные
                    инструменты, которые можно найти или приобрести.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News31/2 1.png')}
                         alt="screenshot"/>
                    На момент релиза будет доступно более 1000 различных рецептов крафта, и это число будет постоянно
                    увеличиваться, предоставляя игрокам новые возможности для экспериментов и развития. Некоторые
                    рецепты будут доступны только при наличии определённых навыков, что добавляет дополнительный уровень
                    сложности и интереса. Кроме того, самые редкие и ценные предметы можно будет создать только при
                    наличии лимитированных чертежей, которые нужно будет находить и изучать в игре.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News31/3 1.png')}
                         alt="screenshot"/>
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News31;
