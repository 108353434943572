import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "shop": 0,
    "owner": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-13')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News13 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №13 (Бизнес - Тату-салон)</h1>
                <p className="newsPageText">
                    Вам предоставляется возможность выбрать новые элементы для татуировок, которые подчеркнут ваш стиль
                    и индивидуальность. В тату-салоне вы найдете широкий ассортимент эскизов для головы, торса, рук и
                    ног, готовых к преображению и украшению в соответствии с вашими предпочтениями.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("shop")}>&#10094;</div>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News13/shop.png')}
                             alt="screenshot"/>
                        <img name="shop" className="slide" src={require('../Asseets/Other/News13/shop2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("shop")}>&#10095;</div>
                    </div>
                    Меню выбора с местом нанесения татуировки
                    Выбрав желаемый дизайн, вы можете с легкостью воплотить его на своем персонаже, добавляя
                    неповторимый штрих к вашему облику. Будь то татуировка с символическим значением или чисто
                    эстетическое украшение.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News13/owner.png')}
                         alt="screenshot"/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News13;
