import './Text.css';

function Text() {
    return (
        <div className="containerText">
            <h1 className="headerText">
                GTA <span className="mp5Text">5MP</span>
            </h1>
            <p className="descriptionText">
                Платформа многопользовательского взаимодействия для игры GTA5 с Role Play уклоном. Начав своё развитие в
                далеком <span style={{color: '#33c6f7'}}>2017</span> году под названием V-MP, мы были одними из
                первых в сфере Role Play, проект прошёл
                долгий путь от идеи к реализации, став местом встречи более <span
                style={{color: '#33c6f7'}}>100.000</span> игроков по всему миру.
                <br/> <br/>Для всех версий GTA5
            </p>
        </div>
    );
}

export default Text;