import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-25')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News25 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №25 (Работа "Инкассатор")</h1>
                <p className="newsPageText">
                    Инкассаторы играют ключевую роль в перемещении денежных средств от бизнесов и банкоматов до крупных
                    банков в зоне их обслуживания. Теперь, если банкомат принадлежит системе Maze Bank, инкассатор
                    должен будет перевести деньги именно в банк Maze. Аналогично, если владелец бизнеса выбрал для
                    обслуживания банк Fleeca, то инкассатор будет перевозить деньги из бизнеса до ближайшего отделения
                    банка Fleeca.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News25/1 1.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News25/1 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News25/1 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News25/1 4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    Владельцы бизнеса не смогут воспользоваться своими деньгами до тех пор, пока инкассатор не доставит
                    их в банк. Для удобства управления, владельцы бизнеса могут самостоятельно включать или выключать
                    режим инкассации, что позволяет контролировать финансовые потоки.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("second")}>&#10094;</div>
                        <img name="second" className="slide" src={require('../Asseets/Other/News25/2 1.png')}
                             alt="screenshot"/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News25/2 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("second")}>&#10095;</div>
                    </div>
                    Работа инкассатора связана с высокой степенью риска. При перевозке денег существует значительная
                    вероятность нападения, что делает необходимым взаимодействие с ближайшими силовыми структурами, будь
                    то полиция или шерифы округа Блейн. Это добавляет дополнительный элемент напряжения и требует
                    стратегического подхода к выполнению заданий.
                    <br/>
                    <br/>
                    P.S. Мини-игра является временной заглушкой для удобства разработки.
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News25;
