import './News.css';

let slideIndex = -1;
let timeout = 7000;
let timer;

timer = setTimeout(showSlides, 100)

function showSlides() {
    if (window.location.pathname !== '/')
        return;

    clearAllNews();
    slideIndex++;
    let slides = document.getElementsByClassName("news");
    if (slideIndex >= slides.length) {
        slideIndex = 0
    }

    clearTimeout(timer);
    setActiveNews(slideIndex);
    timer = setTimeout(showSlides, timeout);
}

function showNews(value) {
    if (window.location.pathname !== '/')
        return;

    clearAllNews();
    setActiveNews(value);
    clearTimeout(timer);
    timer = setTimeout(showSlides, timeout);
}

function clearAllNews() {
    if (window.location.pathname !== '/')
        return;

    let slides = document.getElementsByClassName("news");
    let dots = document.getElementsByClassName("dot");
    for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
    }
    for (let i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
    }
}

function setActiveNews(index) {
    if (window.location.pathname !== '/')
        return;

    let slides = document.getElementsByClassName("news");
    let dots = document.getElementsByClassName("dot");
    if (index >= slides.length) {
        index = 1
    }
    slideIndex = index;
    slides[index].style.display = "block";
    dots[index].className += " active";
}

function News() {
    return (
        <div className="containerNews">
            <div className="news">
                <div className="newsBackGround4">
                    <div className="containerTextNews">
                        <h2 className="newsHeader">Система крафта</h2>
                        <p className="newsDescription">
                            📦 Собирайте и обменивайте предметы, чтобы создавать уникальные вещи и
                            новые игровые возможности!🎮<a href="/news-31">Читать новость</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="news">
                <div className="newsBackGround1">
                    <div className="containerTextNews">
                        <h2 className="newsHeader">Бизнес "Центр развлечений"</h2>
                        <p className="newsDescription">
                        🎮 Центр развлечений: создайте лобби, собирайте друзей и наслаждайтесь мини-играми — гонки,
                            бой насмерть и дерби! 🚗🔫<br/><a href="/news-30">Читать новость</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="news">
                <div className="newsBackGround3">
                    <div className="containerTextNews">
                        <h2 className="newsHeader">Работа "Дальнобойщик"</h2>
                        <p className="newsDescription">
                            Откройте карьеру дальнобойщика 🚛. Исследуйте склады, выбирайте выгодные маршруты и
                            развивайтесь в профессии! 🛤️ <a href="/news-29">Читать новость</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="news">
                <div className="newsBackGround2">
                    <div className="containerTextNews">
                        <h2 className="newsHeader">Работа 'Электрик'</h2>
                        <p className="newsDescription">
                            🔧🚚 Окунись в профессию электрика: получай задания по GPS, работай на высоте и ремонтируй
                            электросети! ⚡<br/><br/><a href="/news-28">Читать новость</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="news">
                <div className="newsBackGround5">
                    <div className="containerTextNews">
                        <h2 className="newsHeader">Работа 'Лесоруб'</h2>
                        <p className="newsDescription">
                            🌲🪓 Начните карьеру лесоруба! Купите топор, рубите деревья и продавайте древесину для прибыли
                            💰<br/><br/><a href="/news-27">Читать новость</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="dots">
                <span className="dot" onClick={() => showNews(0)}></span>
                <span className="dot" onClick={() => showNews(1)}></span>
                <span className="dot" onClick={() => showNews(2)}></span>
                <span className="dot" onClick={() => showNews(3)}></span>
                <span className="dot" onClick={() => showNews(4)}></span>
            </div>
        </div>
    );
}

export default News;