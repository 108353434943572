import './Up.css';
import image from '../../../Asseets/Footer/up.svg'
import scroll from "../../../scroll";

function goToTop() {
    scroll(0)
    var element= document.getElementsByClassName('firstBlock')[0];
    element.scrollIntoView()
}

function Up() {
    return (
        <a className="containerUp" onClick={goToTop}>
            <img className="upItem" src={image} alt={"up"}/>
            <div className="upItem downText">ВВЕРХ</div>
        </a>
    );
}

export default Up;