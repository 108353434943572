import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "default": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-6')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News6 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №6 (Бизнес "Автосалон")</h1>
                <p className="newsPageText">
                    На нашем сервере представлены различные типы автосалонов, включая бюджетные, бизнес-класса, и
                    элитные салоны. В дополнение к этому, мы предлагаем разнообразные виды транспорта: от мотоциклов и
                    водных средств передвижения до коммерческого и воздушного транспорта.
                    <br/>
                    <br/>
                    Особое внимание стоит уделить коммерческому транспорту, где вы сможете приобрести эвакуаторы,
                    грузовики, тягачи, а также различные типы прицепов. Каждый из представленных автомобилей обладает
                    уникальными характеристиками, которые вы можете изучить, выбрав интересующий вас транспортный
                    средство.
                    <br/>
                    <br/>
                    Одной из ключевых особенностей нашего автосалона является возможность владельцам бизнеса управлять
                    ассортиментом предлагаемого транспорта. Они могут добавлять новые модели, снимать с продажи те,
                    которые перестали быть актуальными, а также устанавливать цены на свое усмотрение в рамках доступных
                    им параметров. Тем не менее, процесс управления ассортиментом требует внимательного планирования,
                    ведь каждый заказ транспорта на продажу в автосалоне является динамическим, а значит, владельцам
                    бизнеса необходимо выжидать наилучший момент для покупки.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("default")}>&#10094;</div>
                        <img name="default" className="slide" src={require('../Asseets/Other/News6/autosalon.png')}
                             alt="screenshot"/>
                        <img name="default" className="slide" src={require('../Asseets/Other/News6/autosalon2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="default" className="slide" src={require('../Asseets/Other/News6/autosalon3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="default" className="slide" src={require('../Asseets/Other/News6/ownerPanel.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("default")}>&#10095;</div>
                    </div>
                    <br/>
                    Для наших посетителей мы предоставляем возможность не только выбрать и купить желаемый транспорт, но
                    и совершить тест-драйв перед покупкой. Кроме того, если в момент вашего визита нужного автомобиля не
                    окажется в наличии, вы можете сделать предзаказ и получить его спустя 24 часа.
                    <br/>
                    <br/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье

                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News6;
