import './RunningLine.css';
import image from '../../../Asseets/FirstBlock/RunningLine/opening soon.png'

function RunningLine() {
    return (
        <header className="header">
            <div className="first container">
                <div className="runningLine run run1">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
                <div className="runningLine run run2">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
                <div className="runningLine run run3">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
                <div className="runningLine run run4">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
                <div className="runningLine run run5">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
                <div className="runningLine run run6">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
                <div className="runningLine run run7">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
                <div className="runningLine run run8">
                    <img className="runningLine-img" src={image} alt="img"></img>
                    <div className="runningLine-text">скоро открытие</div>
                </div>
            </div>
        </header>)
}

export default RunningLine;