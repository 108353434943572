import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

const News16 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №16 (Бизнес - Custom Tuning Service)</h1>
                <p className="newsPageText">
                    В мире, где скорость и мастерство на дороге стоят превыше всего, Custom Tuning Service станет вашим
                    надежным партнером в достижении максимальной производительности и улучшения характеристик вашего
                    автомобиля. Ассортимент услуг включает в себя всё, что только можно представить для того, чтобы ваш
                    автомобиль стал лидером на улицах Лос-Сантоса и его окрестностей
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News16/shop.png')}
                         alt="screenshot"/>
                    От улучшения двигателя до чип-тюнинга, каждая позиция в списке услуг имеет несколько уровней
                    улучшения. Повышение уровня обеспечивает значительный прирост скорости, управляемости и тормозных
                    характеристик, делая ваш автомобиль еще более конкурентоспособным на дороге. В Custom Tuning Service
                    каждая деталь важна, поэтому каждая позиция в списке услуг приносит реальные преимущества на трассе.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News16/shop2.png')}
                         alt="screenshot"/>

                    Custom Tuning Service может помочь вам достичь вершин гонок и стать королем дорог Сан-Андреаса.
                    <img className="slideWithoutSlider" src={require('../Asseets/Other/News16/owner.png')}
                         alt="screenshot"/>
                    <span className="word">P.S.</span> Итоговый дизайн интерфейсов будет отличаться от представленного в
                    данной статье
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News16;
