import React from 'react';
import './news.css';
import './news2.css';
import Card from "../Components/Footer/Cards/card";

var indexes = {
    "first": 0,
    "second": 0,
    "third": 0,
}

function nextSlide(tag) {
    indexes[tag]++;
    showSlide(tag);
}

function previousSlide(tag) {
    indexes[tag]--;
    showSlide(tag);
}

function showSlide(tag) {
    if (window.location.pathname !== '/news-24')
        return;

    var x = document.getElementsByName(tag);
    console.log(x)
    if (indexes[tag] >= x.length) {
        indexes[tag] = 0
    }
    if (indexes[tag] < 0) {
        indexes[tag] = x.length - 1
    }
    for (var i = 0; i < x.length; i++) {
        x[i].style.display = "none";
    }
    console.log(indexes[tag])
    x[indexes[tag]].style.display = "block";
}

const News24 = () => {
    return (
        <div className="newsPage">
            <div className="newsPageContainer">
                <a className="logo" href="/"></a>
                <h1 className="newsPageHeader">Новость №24 (Работа "Механик")</h1>
                <p className="newsPageText">
                    В начале вашей карьеры в этой профессии вам будет доступен только пикап механика. Сидя за рулем
                    этого увлекательного транспортного средства, вы сможете предложить свои услуги по ремонту и заправке
                    транспорта других игроков. Механик может действовать как по вызовам, так и активно искать клиентов,
                    кружа по улицам городов в поисках аварийных машин. Даже в случае отсутствия заказов от игроков,
                    механик может выполнять вызовы, поступающие от системы, чтобы всегда быть в центре событий и
                    зарабатывать.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("first")}>&#10094;</div>
                        <img name="first" className="slide" src={require('../Asseets/Other/News24/1 1.png')}
                             alt="screenshot"/>
                        <img name="first" className="slide" src={require('../Asseets/Other/News24/1 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("first")}>&#10095;</div>
                    </div>
                    Починка транспорта требует разводного ключа, который вы можете легко приобрести в магазинах 24/7.
                    Что касается заправки, вам потребуется дополнительный бак для вашего пикапа, который может быть
                    заполнен как одним видом топлива, так и комбинацией всех доступных вариантов на заправках. Однако
                    помните, что общий объем дополнительного бака ограничен 150 литрами. Цена за услуги механика
                    определяется им самим, обеспечивая гибкость в тарифах и учитывая различные виды работ.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("second")}>&#10094;</div>
                        <img name="second" className="slide" src={require('../Asseets/Other/News24/2 1.png')}
                             alt="screenshot"/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News24/2 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News24/2 3.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News24/2 4.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News24/2 5.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News24/2 6.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <img name="second" className="slide" src={require('../Asseets/Other/News24/2 7.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("second")}>&#10095;</div>
                    </div>
                    После достижения 50 уровня навыка механика вам откроется доступ к базовой модели эвакуатора, а после
                    150 уровня навыка вы сможете использовать более мощный и усовершенствованный вариант. Этот мощный
                    инструмент предназначен для эвакуации автомобилей на штрафстоянку, помеченные сотрудниками LSPD и
                    заблокированные колесным замком. Игроки, оказавшиеся в такой ситуации, будут оплачивать не только
                    штраф от полицейских, но и услуги эвакуатора. Более того, каждый игрок может вызвать эвакуатор для
                    своих нужд, а в период бездействия заказов водителя эвакуатора ожидают вызовы от системы, чтобы
                    обеспечить постоянный заработок.
                    <div className="news2Container">
                        <div className="slideButton" onClick={() => previousSlide("third")}>&#10094;</div>
                        <img name="third" className="slide" src={require('../Asseets/Other/News24/3 1.png')}
                             alt="screenshot"/>
                        <img name="third" className="slide" src={require('../Asseets/Other/News24/3 2.png')}
                             alt="screenshot" style={{display: "none"}}/>
                        <div className="slideButton" onClick={() => nextSlide("third")}>&#10095;</div>
                    </div>
                </p>
                <Card/>
            </div>
        </div>
    );
};

export default News24;
